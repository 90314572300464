//
// AMI brand colours
//

$ami-aqua-blue: hsl(193, 100%, 45%);
$ami-aqua-blue-ultra-light: hsl(192, 100%, 95%);
$ami-aqua-blue-light: hsl(192, 100%, 50%);
$ami-aqua-blue-dark: hsl(193, 100%, 40%);

$ami-cool-grey: hsl(240, 0%, 60%);
$ami-dark-blue: hsl(209, 100%, 22%);
$ami-white: hsl(0, 0%, 100%);

$ami-secondary-a: hsl(207, 50%, 53%);
$ami-secondary-b: hsl(47, 100%, 63%);
$ami-secondary-b-ultra-light: hsl(47,50%,90%);
$ami-secondary-c: hsl(183, 38%, 51%);
$ami-secondary-d: hsl(356, 79%, 42%);
$ami-secondary-e: hsl(36, 100%, 48%);
$ami-secondary-f: hsl(84, 65%, 44%);
$ami-secondary-g: hsl(203, 100%, 13%);
$ami-secondary-h: hsl(291, 65%, 22%);
$ami-secondary-i: hsl(231, 49%, 32%);
$ami-secondary-j: hsl(131, 30%, 26%);
$ami-secondary-k: hsl(60, 1%, 23%);
$ami-secondary-l: hsl(192, 82%, 30%);
$ami-secondary-m: hsl(323, 29%, 55%);
$ami-secondary-n: hsl(8, 77%, 53%);
$ami-secondary-o: hsl(352, 76%, 39%);
$ami-secondary-p: hsl(228, 17%, 59%);
$ami-secondary-q: hsl(164, 96%, 30%);
$ami-secondary-r: hsl(0, 0%, 65%);
$ami-secondary-s: hsl(43, 100%, 49%);
$ami-secondary-t: hsl(199, 73%, 55%);
$ami-secondary-u: hsl(182, 100%, 33%);
$ami-secondary-v: hsl(30, 71%, 3%);
$ami-secondary-w: hsl(207, 31%, 75%);

$grey-ultra-light: hsl(0,0%,80%);

//
// Materials for Energy Expo colours
//

$mee-colour-1: rgb(0, 147, 143);
$mee-colour-2: rgb(2, 149, 110);
$mee-colour-3: rgb(5, 110, 141);
$mee-colour-4: rgb(19, 19, 19);
$mee-colour-5: rgb(235, 235, 235);

//
// Magazine colours
//

$cw-colour-1: rgb(0, 165, 170);
$cw-colour-2: rgb(122, 193, 67);

$prw-colour-1: rgb(0,105,66);
$prw-colour-2: rgb(122, 193, 67);

$fse-colour-1: rgb(101, 3, 96);
$fse-colour-2: rgb(122, 193, 67);

$ppe-colour-1: rgb(115, 105, 152);
$ppe-colour-2: rgb(195, 182, 0);

$iw-colour-1: rgb(0, 107, 110);
$iw-colour-2: rgb(181, 18, 27);

$magazines-copy-dark: rgb(51, 51, 51);
$magazines-copy-light: rgb(235, 235, 235);
