@import 'styles/colours';

.Link {

    display: inline-block;
    border: 0px;
    border-radius: 6px;
    cursor: pointer;
    flex: 0 0 auto;
    padding: 8px 14px 6px 14px;
    margin: 0;
    color: var(--colour-link);
    border: 1px solid var(--colour-link);
    background: none;
    transition: all 0.35s ease-in-out;
    font-size: var(--font-size-medium);

    &:hover {
        border: 1px solid var(--colour-link-active);
        color: var(--colour-link-active);
        transition: all 0.35s ease-in-out;
        filter: drop-shadow(0 0 8px var(--colour-link-glow));
    }

    [data-site='materials-energy-expo'] & {
        border: none;
        color: $mee-colour-5;
        background: var(--colour-background-accent-primary);
        text-transform: uppercase;
        padding: 15px;
        &:hover {
            border: none;
            filter: none;
            color: white;
        }
    }

}
